import React from 'react';
import { Redirect } from 'react-router-dom';
import Integrations from './integrations';
import { LocalStorage } from './local-strorage';

class App extends React.Component {
  render() {
    return isUserLoggedIn() ? <Integrations /> : <Redirect to="/login" />;
  }
}

const isUserLoggedIn = () => {
  const expiry = LocalStorage.getApsAccessTokenExpiry();
  return parseInt(expiry ?? '0') > Date.now();
};

export const getEnvName = () => {
  switch (window.location.hostname) {
    case 'localhost':
      return 'localhost';
    case 'integrations.dev.aps.reckon.com':
      return 'dev';
    case 'integrations.stg.aps.reckon.com':
      return 'staging';
    case 'integrations.aps.reckon.com':
      return 'prod';
    case 'integrations.dev.access-aps.com':
      return 'dev';
    case 'integrations.stg.access-aps.com':
      return 'staging';
    case 'integrations.access-aps.com':
      return 'prod';
    default:
      throw new Error("unexpected enviornment encountered");
  }
}

export default App;
