import { v4 as uuidv4 } from 'uuid';
import { getEnvName } from './app';

const ApsLogin = () => {
  let authUrl;
  let clientId;
  const env = getEnvName();

  switch (env) {
    case 'localhost':
    case 'dev':
      authUrl = 'https://auth-portal.dev.aps.reckonapi.com/signin';
      clientId = "1ndpkrncnoa2lv89fpc9u8hhq5";
      break;
    case 'staging':
      authUrl = 'https://auth-portal.stg.aps.reckonapi.com/signin';
      clientId = "6fkf83l8akogekcitqu34euvdf";
      break;
    case 'prod':
      authUrl = 'https://auth-portal.aps.reckonapi.com/signin';
      clientId = "5phmvuf5pk2e0gskdbhteji28f";
      break;
    default:
      throw new Error('unexpected environment encountered');
  }

  const scope = "openid%20profile%20email"
  const state = uuidv4()
  const redirectUrl = `${window.location.origin}/callback`

  window.location.href = `${authUrl}?response_type=token&client_id=${clientId}&scope=${scope}&state=${state}&redirect_uri=${redirectUrl}`;
  return null;
};

export default ApsLogin;
