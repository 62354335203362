import styles from './app.module.css';
import React from 'react';
// import { ReactComponent as ReckonLogo } from './reckon-logo.svg';
import apsLogo from '../assets/APS-Logo.svg';
import BglPanel from './bgl-panel';
import jwt_decode from 'jwt-decode';
import { LocalStorage } from './local-strorage';
import { PulseLoader } from 'halogenium';
import * as ApsApiClient from './aps-api-client';

interface Tenant {
  tenantId: string;
  tenantDomain: string|null;
}

class Integrations extends React.Component {
  state: {
    tenantsLoading: boolean;
    tenants: Tenant[];
    selectedTenantId: string;
  };

  constructor(props) {
    super(props);

    const idToken = LocalStorage.getApsIdToken();
    const decoded = jwt_decode(idToken);
    const tenantIds: string[] = decoded['custom:tenantList'].split(',');
    const tenants = tenantIds.map((tenantId) => ({tenantId, tenantDomain:null}));
    const selectedTenantId = tenantIds[0];
    const tenantsLoading = false;

    this.state = {
      tenantsLoading,
      tenants,
      selectedTenantId 
    }

    LocalStorage.setTenantId(selectedTenantId);
  }

  async componentDidMount() {
    this.setState({ tenantsLoading: true });
    const idToken = LocalStorage.getApsIdToken();
    const decoded = jwt_decode(idToken);
    const apsSub = decoded['custom:apsSub'];
    const sub = decoded['sub'];
    const portalUserId = apsSub ? apsSub : sub;

    const tenants = this.state.tenants;
    const tenantDomains = await ApsApiClient.getTenantDomains(portalUserId, tenants.map(t => t.tenantId)).catch((error) => {
      console.log(`error attempting to get tenant domains. ${JSON.stringify(error)}`);
      this.setState({ tenantsLoading: false });
      return;
    });

    for (let i = 0; i < tenants.length; i++)
      tenants[i].tenantDomain = tenantDomains[i];

    this.setState({ tenantDomains, tenantsLoading: false });
  }

  render() {
    return (
      <div className={styles.app}>
        <header className="flex">
          {/* <ReckonLogo width="75" height="75" /> */}
          <img height={24} src={apsLogo} alt="APS logo" />
          <h1 className="left-space aps-accent"> | </h1>
          <h1 className="left-space">Integrations</h1>
        </header>
        <main>
          {this.renderTenantSelect()}
          <hr />
          <br />
          <BglPanel tenantId={this.state.selectedTenantId} key={this.state.selectedTenantId}/>
          <br />
          <hr />
        </main>
      </div>
    );
  }

  renderTenantSelect() {
    if (this.state.tenantsLoading)
      return <div className = "flex">
        <PulseLoader color="#26A65B" size="5px"/>
      </div>

    return (
      <div className="flex">
        <label>Tenant ID:</label>
        <select onChange={this.handleTenantIdChange} value={this.state.selectedTenantId}>
          {this.state.tenants.map(d => this.renderOption(d.tenantId, d.tenantDomain))}
        </select>
      </div>
    );
  }

  renderOption(tenantId: string, tenantDomain: string) {
    return (
      <option key={tenantId} value={tenantId}>
          { tenantDomain ? tenantDomain : tenantId }
      </option>
    );
  }

  handleTenantIdChange = async (event) => {
    const selectedTenantId = this.getTenantIdFromSelectedValue(event.target.value);
    LocalStorage.setTenantId(selectedTenantId);
    this.setState({ selectedTenantId });
  };

  getTenantIdFromSelectedValue(value: string): string {
    const tenant = this.state.tenants.find(t => t.tenantDomain === value);
    if (tenant) 
      return tenant.tenantId;
    else
      return this.state.tenants.find(t => t.tenantId === value).tenantId;
  }
}

export default Integrations;
