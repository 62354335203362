export class LocalStorage {
  static clear() {
    this.setTenantId('');
    this.setAuthState('');
  }

  static getTenantId(): string {
    return window.localStorage.getItem('tenant-id') || '';
  }

  static getAuthState(): string {
    return window.localStorage.getItem('bgl-integration-auth-state') || '';
  }

  static getApsAccessToken(): string {
    return window.localStorage.getItem('aps-access-token') || '';
  }

  static getApsIdToken(): string {
    return window.localStorage.getItem('aps-id-token') || '';
  }

  static getApsAccessTokenExpiry(): string {
    return window.localStorage.getItem('aps-access-token-expiry') || '';
  }

  static setTenantId(tenantId: string): void {
    window.localStorage.setItem('tenant-id', tenantId);
  }

  static setAuthState(authState: string): void {
    window.localStorage.setItem('bgl-integration-auth-state', authState);
  }

  static setApsAccessToken(authState: string): void {
    window.localStorage.setItem('aps-access-token', authState);
  }

  static setApsAccessTokenExpiry(authState: string): void {
    window.localStorage.setItem('aps-access-token-expiry', authState);
  }

  static setApsIdToken(authState: string): void {
    window.localStorage.setItem('aps-id-token', authState);
  }
}
