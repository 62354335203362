import React from 'react';
import { PulseLoader } from 'halogenium';
import { BglClient } from './bgl-client';
import {Toggle} from "react-toggle-component"
import bglLogo from '../assets/BGL_Dark-1024x991.png';
import { ReadIntegrationResult } from '@reckon.integrations/common'
import { getEnvName } from './app';

interface BglPanelProps {
  tenantId: string;
}

class BglPanel extends React.Component<BglPanelProps> {
  state: {
    integration: Partial<ReadIntegrationResult>;
    integrationLoading: boolean;
    unauthorized: boolean;
    error: string | null;
  };

  constructor(props) {
    super(props);
    this.state = {
      integration: {},
      integrationLoading: false,
      unauthorized: false,
      error: null,
    };
  }

  handleCreateClick = async () => {
    if (getEnvName() === 'localhost') {
      //comment out the lines below if you want to test on localhost, but if 
      //the back-end is also localhost, then the portal user id will be set to 
      //"offlineContext_authorizer_principalId" in dynamodb thus mucking up the integration
      window.alert('If you do this on localhost then portalUserId will not be set in the API.');
      return;
    }

    this.setState({ integrationLoading: true });
    const resp = await BglClient.createIntegration(this.props.tenantId);
    if (resp.status !== 200) {
      window.alert('Failed to create integration');
    }
    this.refreshIntegration();
  }

  handleDeleteClick = async () => {    
    this.setState({ integrationLoading: true });
    const resp = await BglClient.deleteIntegration(this.props.tenantId);
    if (resp.status !== 200)
      window.alert('Failed to delete integration');
    this.refreshIntegration();
  }

  handleEnableDisableClick = async () => {
    this.setState({ integrationLoading: true });
    const resp = await BglClient.toggleBglIntegration(this.props.tenantId);
    if (resp.status !== 200) 
      window.alert('Failed to enable integration');
    this.refreshIntegration();
  };

  handleAuthClick = async () => {
    BglClient.getAuthCode();
  };

  async refreshIntegration() {
    this.setState({ integrationLoading: true });
    await BglClient.getBglIntegrationForTenant(this.props.tenantId)
      .then((bglIntegration) => {
        this.setState({ integration: bglIntegration, unauthorized: false });
      })
      .catch((error) => {
        if (error.response.status === 403) {
          this.setState({ unauthorized: true });
        } else {
          this.setState({ error: error.message });
        }
      });
    this.setState({ integrationLoading: false });
  }

  render() {
    return (
      <div className="flex">
        <img className="flex bgl-logo" src={bglLogo} alt="" width="75" height="75" />
        <div className="flex-column">
          {this.renderTenantControls()}
        </div>
      </div>
    );
  }

  renderTenantControls() {
    if (this.state.error)
      return (
        <h5 className="top-space flex" style={{ color: 'red' }}>
          Failed to get integration from API: {this.state.error}
        </h5>)

    if (this.state.unauthorized) 
      return (
        <h5 className="top-space flex" style={{ color: 'red' }}>
          You are not aurthorized to access this tenant
        </h5>)

    if (this.state.integrationLoading)
      return <div className = "top-space flex">
        <PulseLoader color="#26A65B" size="16px" margin="4px"/>
      </div>

    //Note that buttons like the create and delete ones presented to the user in this test-ui likely won't be present
    //on the actual integrations UI that we build. On the actual integrations UI we will probably just have controls
    //to turn the integration on or off and the logic for dealing with different states of the integration like 'not present',
    //'created', 'errored out' etc., and what needs to be done for those states, would be hidden from the user.

    if (!this.state.integration)
      return (
        <button className="top-space flex button" onClick={this.handleCreateClick}>
          {getEnvName() === 'localhost' ? 'Initialize Integration (avoid on localhost)' : 'Initialize Integration' }
        </button>)

    const integration = this.state.integration;
    const integrationError = integration.integrationStatus === "error";

    if (integrationError)
      return (<div className="flex-row">
        <div className="flex error-message">Integration status = Error</div>
      </div>)

    return (
      <div className="flex-row">
        <label className="toggle" htmlFor="toggle-1">
          {this.state.integration.enabled ? 'Enabled' : 'Disabled'}
          <Toggle
            name="toggle-1"
            checked={this.state.integration.enabled}
            leftBackgroundColor="grey"
            rightBackgroundColor="green"
            borderColor="black"
            knobColor="white"       
            onToggle={this.handleEnableDisableClick}
          />
        </label>
        <button 
          className={integration.authData ? "button authenticated-button" : "button unauthenticated-button"} 
          onClick={this.handleAuthClick}
        >
          {integration.authData ? 'Authenticated' : 'Not Authenticated'}
        </button>
        <button className="left-space button delete-button" onClick={this.handleDeleteClick}>
          Delete
        </button>
      </div>
    )
  }

  async componentDidMount() {
    this.setState({ integrationLoading: true });
    const bglIntegration = await BglClient.getBglIntegrationForTenant(this.props.tenantId).catch((error) => {
      if (error.response && error.response.status === 403) {
        this.setState({ unauthorized: true });
      } else {
        this.setState({ error: error.message });
      }
    });
    this.setState({ integration: bglIntegration, integrationLoading: false });
  }
}

export default BglPanel;
