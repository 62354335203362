import { Redirect } from 'react-router-dom';
import { LocalStorage } from './local-strorage';

const ApsAuthCallBack = () => {
  const parsedHash = new URLSearchParams(window.location.hash.substr(1));
  const accessToken = parsedHash.get('access_token');
  const idToken = parsedHash.get('id_token');
  const expiryTime = parsedHash.get('expires_in');
  if (!accessToken || !expiryTime || !idToken) {
    alert('Could not complete login, please try again');
    return <Redirect to="/login" />;
  } else {
    const expiryInMs = parseInt(expiryTime) * 1000;
    LocalStorage.setApsAccessTokenExpiry((Date.now() + expiryInMs).toString());
    LocalStorage.setApsAccessToken(accessToken);
    LocalStorage.setApsIdToken(idToken);
    return <Redirect to="/" />;
  }
};

export default ApsAuthCallBack;
