import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './app/app';
import ApsAuthCallBack from './app/aps-auth-callback';
import ApsLogin from './app/aps-login';
import IntegrationOauthCodeCallback from './app/integration-oauth-code-callback';

ReactDOM.render(
  <StrictMode>
    <Router>
      <Switch>
        <Route exact path="/">
          <App />
        </Route>
        <Route exact path="/login" component={ApsLogin} />
        <Route exact path="/callback" component={ApsAuthCallBack} />
        <Route exact path="/bgl-callback" render={(props) => <IntegrationOauthCodeCallback {...props} partner={'bgl'} />} />
      </Switch>
    </Router>
  </StrictMode>,
  document.getElementById('root')
);
