import React from 'react';
import { Redirect } from 'react-router-dom';
import { BglClient } from './bgl-client';
import { LocalStorage } from './local-strorage';

interface IntegrationOauthCodeCallbackProps {
  partner: string;
}

class IntegrationOauthCodeCallback extends React.Component<IntegrationOauthCodeCallbackProps> {
  state: {
    responseReturned: boolean;
  };

  constructor(props: IntegrationOauthCodeCallbackProps) {
    super(props);
    this.state = { responseReturned: false };
  }

  async componentDidMount() {
    try {
      const urlParams = new URL(window.location.toString()).searchParams;
      const authCode = urlParams.get('code');
      const authState = urlParams.get('state');
      const partner = this.props.partner;

      if (authCode && authState) {
        const isValidState = LocalStorage.getAuthState() === authState;
        if (isValidState) {
          const tenantId = LocalStorage.getTenantId();
          switch (partner) {
            case 'bgl': {
              const resp = await BglClient.getAuthToken(tenantId, authCode);
              if (resp.status !== 200) {
                window.alert('Authentication Failed');
              }
              this.setState({ responseReturned: true });
            }
          }
        } else {
          window.alert('Authentication Failed');
        }
      } else {
        window.alert('Authentication Failed');
      }
      this.setState({ responseReturned: true });
    } catch (err) {
      window.alert('Authentication Failed');
      this.setState({ responseReturned: true });
    }
  }

  render() {
    const { responseReturned } = this.state;
    return responseReturned ? <Redirect to="/" /> : <h1>Loading...</h1>;
  }
}

export default IntegrationOauthCodeCallback;
