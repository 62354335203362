import axios, { AxiosResponse } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { getEnvName } from './app';
import { ReadIntegrationResult } from '@reckon.integrations/common'
import { LocalStorage } from './local-strorage';

export class BglClient {
  public static getAuthCode() {
    const redirectUrl = encodeURI(window.location.origin) + '/bgl-callback';
    const authState = uuidv4();
    LocalStorage.setAuthState(authState);

    const env = getEnvName();
    let clientId;
    let bglAuthUrlPrefix;
    switch (env) {
      case 'localhost':
      case 'dev':
        clientId = 'bd57ad03-88c9-4a37-ab3f-5162454e5b97';
        bglAuthUrlPrefix = 'https://integrations.uat.bgl360.com.au/u/oauth/authorize';
        break;
      case 'staging':
        clientId = '4a8a2653-f0e2-4ce5-9baa-646d147ef45b';
        bglAuthUrlPrefix = 'https://integrations.staging.bgl360.com.au/u/oauth/authorize';
        break;
      case 'prod':
        clientId = 'ac3f6e22-f7c9-429b-b0f2-9cccd8a05132';
        bglAuthUrlPrefix = 'https://integrations.bgl360.com.au/u/oauth/authorize';
        break;
      default:
        throw new Error('unexpected environment encountered');
    }

    window.location.href = bglAuthUrlPrefix +
      `?response_type=code&client_id=${clientId}&scope=integration_post_sync&state=${authState}&redirect_uri=${redirectUrl}`;
  }

  public static async getAuthToken(tenantId: string, authCode: string): Promise<AxiosResponse> {
    const redirectUrl = encodeURI(window.location.origin) + '/bgl-callback';
    const body = JSON.stringify({
      authData: {
        authCode,
        redirectUrl,
      },
    });
    return await axios.post(`${this.getIntegrationsUrl()}/${tenantId}/integrations/bgl/actions/authenticate`, body, this.getRequestOptions());
  }

  public static async toggleBglIntegration(tenantId: string): Promise<AxiosResponse> {
    return await axios.post(`${this.getIntegrationsUrl()}/${tenantId}/integrations/bgl/actions/toggle`, JSON.stringify({}), this.getRequestOptions())
      .catch((error) => {
        return error.response;
      });
  }

  public static async getBglIntegrationForTenant(tenantId: string): Promise<ReadIntegrationResult | undefined> {
    const integrations = (await axios.get<ReadIntegrationResult[]>(`${this.getIntegrationsUrl()}/${tenantId}/integrations`, this.getRequestOptions())).data;
    return integrations.find((integration) => integration.integrationPartner === 'BGL');
  }

  public static async createIntegration(tenantId: string): Promise<AxiosResponse> {
    return await axios.post(`${this.getIntegrationsUrl()}/${tenantId}/integrations/bgl`, JSON.stringify({}), this.getRequestOptions())
      .catch((error) => {
        return error.response;
      });
  }

  public static async deleteIntegration(tenantId: string): Promise<AxiosResponse> {
    return await axios.delete(`${this.getIntegrationsUrl()}/${tenantId}/integrations/bgl`, this.getRequestOptions())
      .catch((error) => {
        return error.response;
      });
  }

  private static getRequestOptions() {
    const apsToken = LocalStorage.getApsAccessToken();
    return { headers: { authorizationtoken: apsToken } };
  }

  private static getIntegrationsUrl() {
    const env = getEnvName();
    switch (env) {
      case 'localhost':
        return 'http://localhost:3333/v1';
      case 'dev':
        return 'https://integrations-api.dev.access-aps.com/v1';
      case 'staging':
        return 'https://integrations-api.stg.access-aps.com/v1';
      case 'prod':
        return 'https://integrations-api.access-aps.com/v1';
      default:
        throw new Error('unexpected environment encountered');
    }
  }
}
