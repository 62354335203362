// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".app_app__j3Q8W {\n  font-family: sans-serif;\n  min-width: 300px;\n  max-width: 600px;\n  margin: 50px auto;\n}\n\n.app_app__j3Q8W .flex {\n  display: flex;\n  flex: 1;\n  align-items: center;\n  justify-content: center;\n}\n\n.app_app__j3Q8W .flex-row {\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  align-self: flex-start;\n}\n\n.app_app__j3Q8W .flex-column {\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n}\n\n.app_app__j3Q8W .left-space {\n  margin-left: 10px;\n}\n\n.app_app__j3Q8W .top-space {\n  margin-top: 10px;\n}\n\n.app_app__j3Q8W header {\n  background-color: #143055;\n  color: white;\n  padding: 5px;\n  border-radius: 3px;\n}\n\n.app_app__j3Q8W main {\n  margin-top: 10px;\n}\n\n.app_app__j3Q8W .aps-accent {\n  color: rgb(62, 186, 99);\n}\n\n.app_app__j3Q8W .bgl-logo {\n  margin-left: 100px;\n  margin-right: 10px;\n  flex: auto 0 0;\n}\n\n.app_app__j3Q8W .error-message {\n  color: red;\n}\n\n.app_app__j3Q8W .button {\n  display: flex;\n  flex: 0 1;\n  text-align: center;\n  border-color: black;\n  font-size: 16px;\n  cursor: pointer;\n  border-radius: 12px;\n  border-block-width: 1px;\n  padding: 2px 10px;\n  white-space: nowrap;\n}\n\n.app_app__j3Q8W .delete-button {\n  color: black;\n}\n\n.app_app__j3Q8W .authenticated-button {\n  background-color: #4CAF50;\n  color: white;\n}\n\n.app_app__j3Q8W .unauthenticated-button {\n  background-color: red;\n  color: white;\n}\n\n.app_app__j3Q8W .toggle {\n  width: 120px;\n}", ""]);
// Exports
exports.locals = {
	"app": "app_app__j3Q8W"
};
module.exports = exports;
