import axios from 'axios';
import { LocalStorage } from './local-strorage';
import { getEnvName } from './app';

export async function getTenantDomains(portalUserId: string, tenantIds: string[]): Promise<string[]> {
  const url = getCoreApsApiUrl() + `/users/domains/${portalUserId}`;
  const body = JSON.stringify(tenantIds);
  const res = await axios.post(url, body, getRequestOptions());
  return res.data.map(r => r.domainName);
}

function getRequestOptions() {
  const apsToken = LocalStorage.getApsAccessToken();
  return { headers: { authorizationtoken: apsToken } };
}

function getCoreApsApiUrl() {
  const env = getEnvName();
  switch (env) {
    case 'localhost':
    case 'dev':
      return 'https://core-api.dev.access-aps.com';
    case 'staging':
      return 'https://core-api.stg.access-aps.com';
    case 'prod':
      return 'https://core-api.access-aps.com';
    default:
      throw new Error('unexpected environment encountered');
  }
}
